import React, { useState } from "react";

import { navigate } from "gatsby";
import { gql, useQuery } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";

import Logger from "../../utils/Logger";
import { ImgToBase64 } from "../../utils/Base64Img";
import Server from "../../utils/Server";

import Loader from "../Loader";

import BackButton from "../../images/back_arrow_icon@2x.png";
import UserIcon from "../../images/profile_user_icon@2x.png";
import DefaultImg from "../../images/profile-pic-icon.png";
import PhoneIcon from "../../images/profile_phone_icon@2x.png";
import EmailIcon from "../../images/profile_mail_icon@2x.png";
import GenderIcon from "../../images/Profile_gender_icon@2x.png";

import * as AddManagerStyle from "./AddManager.module.scss";
import { UploadMedia } from "../../utils/UploadFile";
import AppState from "../../States/AppState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDungeon, faListAlt } from "@fortawesome/free-solid-svg-icons";

interface ManagerType {
  id: string;
  name: string;
  code: string;
}

interface managerQuery {
  userRoles: {
    collection: ManagerType[];
  };
}

interface AddManagerInputs {
  name: string;
  contactNumber: string;
  role: string;
  email?: string;
  gender?: string;
  fastTag?: string;
}

interface Props {
  mangerType: string;
  areaId: string;
}

const userQuery = gql`
  query UserRoles($description: String) {
    userRoles(description: $description) {
      collection {
        id
        name
        code
      }
    }
  }
`;

const Index = ({ mangerType, areaId }: Props) => {
  const { register, handleSubmit } = useForm();
  const [profileImg, setProfileImg] = useState<{ file: File; url: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const { data: managerTypes } = useQuery<managerQuery>(userQuery, {
    variables: {
      description:
        mangerType === "domestic" ? "USER_DOMESTIC_HELP" : "PARTNER_MANAGER",
    },
  });

  const onHandleSubmit: SubmitHandler<AddManagerInputs> = (data) => {
    setIsLoading(true);
    const role =
      mangerType === "domestic"
        ? [data.role, "ROLE_USER", "ROLE_DOMESTIC"]
        : [data.role, "ROLE_PARTNER"];

    Server.post(`/parkingarea/${areaId}/manager`, {
      ...data,
      email: data.email?.length ? data.email : undefined,
      fastTag: data.fastTag?.length ? data.fastTag : undefined,
      role: role,
    })
      .then((res: any) => {
        if (profileImg?.file) {
          uploadFile(profileImg.file, res?.manager)
            .catch((err) => {
              Logger.showError(err);
            })
            .finally(() => {
              setIsLoading(false);
              navigate(-1);
            });
        } else {
          setIsLoading(false);
          navigate(-1);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        Logger.showError(err);
      });
  };

  const uploadFile = (file: File, userId: number) => {
    return new Promise((resolve, reject) => {
      UploadMedia({
        name: file.name,
        path: file,
        type: file.type,
      })
        .then((res) => {
          Server.get(`/user/${userId}/media`, {
            keyname: res.key,
            mediaType: "PHOTO",
          })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => reject(err));
    });
  };

  return (
    <>
      <div className={AddManagerStyle.container}>
        <div className={AddManagerStyle.header}>
          <span>Add Parking Manager</span>
          <button onClick={() => navigate(-1)}>
            <img src={BackButton} alt="back" width={32} height={32} />
          </button>
        </div>
        <div className={AddManagerStyle.content}>
          <div className={`${AddManagerStyle.content__img} grediant1`}>
            <label htmlFor="files">
              <img
                src={profileImg?.url || DefaultImg}
                alt="manager"
                width={84}
                height={84}
                {...{
                  style: {
                    borderRadius: "100px",
                  },
                }}
              />
              <span className={AddManagerStyle.badge}>Add</span>
            </label>
            <input
              type="file"
              id="files"
              name="files"
              onChange={(e) => {
                const files = e.target.files;
                if (files) {
                  ImgToBase64(files[0])
                    .then((base64: any) => {
                      setProfileImg({ file: files[0], url: base64 });
                    })
                    .catch((err) => {
                      Logger.showError(err);
                    });
                }
              }}
              accept="image/png, image/gif, image/jpeg"
              hidden
            />
          </div>
          <form
            onSubmit={handleSubmit(onHandleSubmit)}
            className={AddManagerStyle.form}
          >
            <div className={AddManagerStyle.form__group}>
              <FontAwesomeIcon icon={faListAlt}></FontAwesomeIcon>
              {/* <img src={UserIcon} alt="name" height={32} /> */}
              <div>
                <label htmlFor="name">Category</label>
                <select
                  className={`${AddManagerStyle.form__group__input} ${AddManagerStyle.select}`}
                  placeholder="Select Manager Type"
                  required
                  {...register("role")}
                >
                  <option value="">Select One</option>
                  {managerTypes?.userRoles.collection.map(
                    ({ id, name, code }) => (
                      <option key={id} value={code}>
                        {name}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
            <div className={AddManagerStyle.form__group}>
              <img src={UserIcon} alt="name" height={32} />
              <div>
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  className={AddManagerStyle.form__group__input}
                  placeholder="Enter Manager Name"
                  required
                  {...register("name")}
                />
              </div>
            </div>
            <div className={AddManagerStyle.form__group}>
              <img src={PhoneIcon} alt="name" height={32} />
              <div>
                <label htmlFor="contact">Contact Number</label>
                <input
                  id="contact"
                  type="number"
                  className={AddManagerStyle.form__group__input}
                  placeholder="Enter Contact Number"
                  required
                  {...register("contactNumber")}
                />
              </div>
            </div>
            {mangerType === "domestic" && (
              <div className={AddManagerStyle.form__group}>
                {/* <img src={EmailIcon} alt="name" height={32} /> */}
                <FontAwesomeIcon icon={faDungeon}></FontAwesomeIcon>
                <div>
                  <label htmlFor="fastTag">Entry Tag (Optional)</label>
                  <input
                    id="fastTag"
                    type="text"
                    className={AddManagerStyle.form__group__input}
                    placeholder="Enter Entry Tag"
                    {...register("fastTag")}
                  />
                </div>
              </div>
            )}

            <div className={AddManagerStyle.form__group}>
              <img src={EmailIcon} alt="name" height={32} />
              <div>
                <label htmlFor="email">Email (Optional)</label>
                <input
                  id="email"
                  type="email"
                  className={AddManagerStyle.form__group__input}
                  placeholder="Enter Email Address"
                  {...register("email")}
                />
              </div>
            </div>
            <div className={AddManagerStyle.form__group}>
              <img src={GenderIcon} alt="name" height={32} />
              <div>
                <label htmlFor="name">Gender</label>
                <select
                  className={`${AddManagerStyle.form__group__input} ${AddManagerStyle.select}`}
                  placeholder="Select Manager Type"
                  {...register("gender")}
                >
                  <option value="">Select One</option>
                  {["MALE", "FEMALE", "OTHER"].map((val) => (
                    <option value={val} key={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={AddManagerStyle.footer}>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default Index;
