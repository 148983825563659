// extracted by mini-css-extract-plugin
export var container = "AddManager-module--container--JBmLf";
export var header = "AddManager-module--header--ybK0S";
export var content = "AddManager-module--content--SL5nv";
export var content__img = "AddManager-module--content__img--elvc2";
export var form = "AddManager-module--form--Fxado";
export var form__group = "AddManager-module--form__group--A3vYn";
export var form__group__input = "AddManager-module--form__group__input--4g21h";
export var footer = "AddManager-module--footer--GsuFX";
export var badge = "AddManager-module--badge--hCwW-";
export var select = "AddManager-module--select--IAttW";